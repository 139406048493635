.App-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.App-header {
    font-size: calc(10px + 2vmin);
}

.App-logo {
    object-fit: cover;
    height: auto;
}

.App-header-title {
    font-size: xx-large;
    margin-top: -20px;
    margin-bottom: -2px;
}

.App-header-summary-text {
    font-weight: 600;
    font-size: larger;
}

.Download-on-app-store-icon {
    width: 15ch;
}

.App-header-ios-requirement {
    font-size: small;
    color: rgba(0,0,0,0.4);
    padding-top: 8px;
}

@media screen and (min-width: 551px) {
    .App-logo {
        width: 55vmin;
    }

    .App-header-summary-text {
        max-width: 35vw;
    }
}

@media screen and (max-width: 550px) {
    .App-logo {
        width: 95vmin;
    }

    .App-header-summary-text {
        max-width: 90vw;
    }
}