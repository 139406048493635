.Privacy-container {

}

.Privacy-text {
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
}

@media screen and (min-width: 551px) {
    .Privacy-text {
        width: 70vw;
    }
}

@media screen and (max-width: 550px) {
    .Privacy-text {
        width: 75vmin;
    }

    .App-header-summary-text {
        max-width: 90vw;
    }
}