a {
    color: rgb(103, 73, 130);
    /*text-decoration: none;*/
}

.App-footer-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.App-footer-link-container {
    display: flex;
    align-self: center;
    padding-top: 5vh;
}

.App-footer-link {
    color: rgba(0, 0, 0, 0.5);
    padding-left: 2px;
    padding-right: 2px;
}

.App-footer-credit-line {
    align-self: center;
    padding: 35px;
    color: rgba(0, 0, 0, 0.4);
    font-size: x-small;
    max-width: 35em;
}