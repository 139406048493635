.Feature-container {
    margin-left: auto;
    margin-right: auto;
}

.Feature-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.Feature-column {
    display: flex;
    flex-flow: column;
    flex: 1;
}

.Feature-cell-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.Feature-cell-title {
    font-weight: 500;
    font-size: x-large;
    margin-bottom: 5px;
}

.Feature-line-break {
    visibility: hidden;
}

.Feature-cell-body {
    font-weight: 425;
    width: 90%;
    text-align: left;
    line-height: 1.5;
}

@media screen and (min-width: 551px) {
    .Feature-column {
        min-width: 250px;
        max-width: 400px;
    }
}

@media screen and (max-width: 550px) {
    .Feature-column {
        min-width: 100vw;
    }
}