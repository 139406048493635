.Nav-item-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
    width: 90%;
}

.Nav-item-logo {
    display: flex;
    width: 25px;
    height: auto;
    padding-top: 10px;
    /*padding: 5px;*/
}

.Nav-thematic-break {
    background-color: rgba(0, 0, 0, 0.15);
    height: 1px;
    border: none;
}

@media screen and (min-width: 551px) {

}

@media screen and (max-width: 550px) {

}